import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { UiText } from '@uireact/text';
import { UiSpacing } from '@uireact/foundation';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { MenuExample } from './utils';
import { UiMenu } from '../src/';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <h1 {...{
      "id": "uimenu"
    }}>{`UiMenu`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/menu/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component to render menu options`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/menu`}</p>
    </blockquote>
    <h2 {...{
      "id": "uimenu-1"
    }}>{`UiMenu`}</h2>
    <Playground __position={1} __code={'<MenuExample />'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiText,
      UiSpacing,
      packageJson,
      Playground,
      MenuExample,
      UiMenu,
      DefaultLayout,
      _frontmatter
    }} hideThemeSelector mdxType="Playground">
  <MenuExample mdxType="MenuExample" />
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiMenu} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      